import {
  ActionIcon,
  createStyles,
  Group,
  Paper,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import React, { useContext, useState } from 'react';
import IUser from '../../models/IUser';
import { UserEditDialog } from './UserEditDialog';
import { UserDeleteDialog } from './UserDeleteDialog';
import { UserCreateDialog } from './UserCreateDialog';
import { UserRestoreDialog } from './UserRestoreDialog';
import ISection from '../../models/ISection';
import { UserContext } from '../../context/ContextWrapper';
import { AbstractContextType } from '../../context/AbstractProvider';
import { RoleElement } from '../entityelements/RoleElement';
import { ROLE_ENUM_VALUES } from '../../api_enums/ROLE_ENUM';
import { useTranslation } from 'react-i18next';
import { RADIO_CALL_PREFIX_ENUM_VALUES } from '../../api_enums/RADIO_CALL_PREFIX_ENUM';
import cx from 'clsx';
import { Square, SquareCheck, X } from 'tabler-icons-react';
import { searchUser } from '../../utility/search';

const useStyles = createStyles((theme) => ({
  header: {
    zIndex: 1,
    position: 'sticky',
    top: 0,
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    transition: 'box-shadow 150ms ease',
    after: {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: 'rem(1px) solid',
    },
  },
  scrolled: {
    boxShadow: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05)',
  },
}));

function createRows(elements: IUser[], searchValue: string) {
  return elements
    .filter((element) => {
      return searchUser(element, searchValue);
    })
    .map((element) => {
      let roleCounter = element.roles.length;
      return (
        <tr key={element.id}>
          <td> {element.name} </td>
          <td> {(element.assigned_to as ISection)?.name} </td>
          <td>
            {' '}
            {
              RADIO_CALL_PREFIX_ENUM_VALUES.get(element.radio_call_name_prefix)
                ?.label
            }{' '}
            {element.radio_call_name}{' '}
          </td>
          <td>
            {' '}
            {
              RADIO_CALL_PREFIX_ENUM_VALUES.get(element.radio_call_group_prefix)
                ?.label
            }{' '}
            {element.radio_call_group}{' '}
          </td>
          <td> {element.phone} </td>
          <td>
            {element.roles.length > 0 && (
              <>
                {element.roles.slice(0, 8).map((r: string) => (
                  <Tooltip key={r} label={<RoleElement value={r} />} withArrow>
                    <div>{ROLE_ENUM_VALUES.get(r)?.icon}</div>
                  </Tooltip>
                ))}
              </>
            )}
          </td>
          <td>
            {element.is_active ? (
              <SquareCheck color={'grey'} />
            ) : (
              <Square color={'grey'} />
            )}
          </td>
          <td>
            <Group spacing="xs">
              <UserEditDialog user={element} />
              <UserRestoreDialog user={element} />
              <UserDeleteDialog user={element} />
            </Group>
          </td>
        </tr>
      );
    });
}

export function UserTable() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const ctx = useContext(UserContext) as AbstractContextType<IUser>;
  const [scrolled, setScrolled] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  return (
    <Paper withBorder radius="md" p="xs">
      <Stack>
        <Title order={2}>{t('UserTable.Title')}</Title>
        <Group>
          <UserCreateDialog />
        </Group>

        <TextInput
          radius="md"
          size="md"
          rightSection={
            searchValue && (
              <ActionIcon onClick={() => setSearchValue('')}>
                {' '}
                <X />{' '}
              </ActionIcon>
            )
          }
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <ScrollArea
          h={'85vh'}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table striped verticalSpacing="xs" fontSize="md">
            <thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <tr>
                <th>{t('UserTable.Username')}</th>
                <th>{t('UserTable.MissionSection')}</th>
                <th>{t('UserTable.RadioCallName')}</th>
                <th>{t('UserTable.RadioCallGroup')}</th>
                <th>{t('UserTable.PhoneNumber')}</th>
                <th>{t('UserTable.Roles')}</th>
                <th>{t('UserTable.Active')}</th>
                <th>{t('UserTable.Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {createRows(ctx.entities, searchValue)}
              {ctx.loading &&
                [1, 2].map((id) => {
                  return (
                    <tr key={id}>
                      <td>
                        <Skeleton height={12} mt={6} width="50%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="70%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="60%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="70%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="20%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="60%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="20%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="60%" radius="xl" />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </ScrollArea>
        {!ctx.loading && ctx.entities.length == 0 && (
          <Text align="center">{t('NoUsersAvailable.NoUsersAvailable')}</Text>
        )}
      </Stack>
    </Paper>
  );
}
