import {
  ActionIcon,
  createStyles,
  Group,
  Paper,
  ScrollArea,
  SimpleGrid,
  Skeleton,
  Space,
  Stack,
  Switch,
  Table,
  Text,
  TextInput,
  Title,
} from '@mantine/core';

import { ApproveDialog } from './ApproveDialog';
import { ApprovalRejectDialog } from './ApprovalRejectDialog';
import React, { useContext, useState } from 'react';
import IResource from '../../../models/IResource';
import { InquiryContext } from '../../../context/ContextWrapper';
import { AbstractContextType } from '../../../context/AbstractProvider';
import IInquiry, { getPrefixedInquiryId } from '../../../models/IInquiry';
import ILocation from '../../../models/ILocation';
import IUser from '../../../models/IUser';
import ISection from '../../../models/ISection';
import { InquiryStatusElement } from '../InquiryStatusElement';
import { InquiryPriorityElement } from '../InquiryPriorityElement';
import { InquiryEditDialog } from '../InquiryEditDialog';
import { ArrowNarrowDown, ArrowNarrowUp, X } from 'tabler-icons-react';
import { getInquiryPriority } from '../../../api_enums/INQUIRY_PRIORITY_ENUM';
import { AuthContext, AuthContextType } from '../../../context/AuthProvider';
import { ChangeHistoryDialog } from '../../history/ChangeHistoryDialog';
import {
  INQUIRY_STATUS_ENUM,
  INQUIRY_STATUS_ENUM_VALUES,
} from '../../../api_enums/INQUIRY_STATUS_ENUM';
import { useTranslation } from 'react-i18next';
import IPackageUnit from '../../../models/IPackageUnit';
import moment from 'moment';
import UserService from '../../../services/UserService';
import { showAppNotifcation } from '../../../utility/NotificationConfigs';
import { useInputState } from '@mantine/hooks';
import { sortInquiresByStatusPriorityId } from '../../../utility/sorter';
import { InquiryCommentDialog } from '../InquiryCommentDialog';
import cx from 'clsx';
import { InquiryRecipientInfo } from '../InquiryRecipientInfo';
import { InquiryRequesterInfo } from '../InquiryRequesterInfo';
import { searchInquiry } from '../../../utility/search';

const useStyles = createStyles((theme) => ({
  header: {
    zIndex: 1,
    position: 'sticky',
    top: 0,
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    transition: 'box-shadow 150ms ease',
    after: {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: 'rem(1px) solid',
    },
  },
  scrolled: {
    boxShadow: '0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05)',
  },
}));

function createRows(
  elements: IInquiry[],
  userId: number,
  sectionId: number,
  showAllEntries: boolean,
  searchValue: string,
) {
  function showAmountChangeIndicator(element: IInquiry) {
    let sumSinceLastUserChange = 0;
    const field = 'amount';

    for (let i = 0; i < element.history.length; i++) {
      if (
        element.history[i] &&
        element.history[i].change &&
        field in element.history[i].change
      ) {
        sumSinceLastUserChange +=
          (element.history[i].change[field].current as any) -
          (element.history[i].change[field].before as any);
      }
    }
    return sumSinceLastUserChange === 0 ? (
      <></>
    ) : sumSinceLastUserChange > 0 ? (
      <ArrowNarrowUp size={20} color="green" />
    ) : (
      <ArrowNarrowDown size={20} color="red" />
    );
  }

  function showPriorityChangeIndicator(element: IInquiry) {
    const field = 'priority';
    let luc;

    for (let i = 0; i < element.history.length; i++) {
      if (
        element.history[i] &&
        element.history[i].change &&
        field in element.history[i].change
      ) {
        luc = element.history[i].change[field].before;
      }
    }
    return !luc ||
      getInquiryPriority(element.priority) ===
        getInquiryPriority(luc.toString()) ? (
      <></>
    ) : getInquiryPriority(element.priority) <
      getInquiryPriority(luc.toString()) ? (
      <ArrowNarrowUp size={13} color="grey" />
    ) : (
      <ArrowNarrowDown size={13} color="grey" />
    );
  }

  return elements
    .filter((element) => {
      return (
        (showAllEntries ||
          ((element as IInquiry).is_waiting_at as ISection)?.id ===
            sectionId) &&
        searchInquiry(element, searchValue)
      );
    })
    .sort((a, b) => sortInquiresByStatusPriorityId(a, b))
    .map((element) => {
      return (
        <tr key={element.id}>
          <td> {getPrefixedInquiryId(element.id)} </td>
          <td>
            {moment
              .utc(element.created_at)
              .local()
              .format('DD.MM.YYYY - HH:mm:ss')}
          </td>
          <td>
            <InquiryStatusElement status={element.status} />
          </td>
          <td>
            <InquiryPriorityElement value={element.priority} />
            {showPriorityChangeIndicator(element)}
          </td>
          <td>
            {' '}
            {element.amount}
            {showAmountChangeIndicator(element)}
          </td>
          <td>
            {' '}
            {(element.inquired_is as IResource)?.name} (
            {
              ((element.inquired_is as IResource)?.package_unit as IPackageUnit)
                ?.name
            }
            )
          </td>
          <td> {(element.deliver_to as ILocation)?.name} </td>
          <td> {(element.inquired_by as IUser)?.name} </td>
          <td> {(element.is_waiting_at as ISection)?.name} </td>
          <td> {(element.ordered_from as ISection)?.name} </td>
          <td>{element?.requester}</td>
          <td>{element?.recipient}</td>
          <td>
            <Group spacing="xs">
              <ApproveDialog inquiry={element} />
              <InquiryEditDialog inquiry={element} />
              <ApprovalRejectDialog inquiry={element} />
              <InquiryRequesterInfo
                requester={element.requester_info}
                id={element.id}
              />
              <ChangeHistoryDialog
                id={element.id}
                history={element.history}
                currentStatus={element.status}
                statusEnum={INQUIRY_STATUS_ENUM}
                statusEnumValues={INQUIRY_STATUS_ENUM_VALUES}
              />
              {element.comment && (
                <InquiryCommentDialog
                  comment={element.comment}
                  id={element.id}
                />
              )}
              {element.recipient_info && (
                <InquiryRecipientInfo
                  recipient={element.recipient_info}
                  id={element.id}
                />
              )}
            </Group>
          </td>
        </tr>
      );
    });
}

function ApprovalTable() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const ctx = useContext(InquiryContext) as AbstractContextType<IInquiry>;
  const { userInfo } = useContext(AuthContext) as AuthContextType;
  const { getOptions, setOptions } = useContext(AuthContext) as AuthContextType;
  const [searchValue, setSearchValue] = useState('');
  const options = getOptions();
  const [showAllEntries, setShowAllEntries] = useInputState(
    options.show_approve_indicators,
  );
  const [scrolled, setScrolled] = useState(false);

  const updateOptions = (show_approve_indicators: boolean) => {
    const opt = {
      ...options,
      show_approve_indicators: show_approve_indicators,
    };
    UserService.saveOptions({ options: opt })
      .then(() => {
        setOptions(opt);
      })
      .catch(() => showAppNotifcation(false, t('main.OptionCannotChange')));
  };

  return (
    <Paper withBorder radius="md" p="xs">
      <Stack>
        <Title order={2}>{t('ApprovalTable.Title')}</Title>
        <SimpleGrid cols={2}>
          <Group></Group>
          <Group position="right">
            <Switch
              labelPosition="left"
              label="Alle Anfragen anzeigen"
              color="green"
              checked={showAllEntries}
              onChange={(event) => {
                setShowAllEntries(event.currentTarget.checked);
                updateOptions(event.currentTarget.checked);
              }}
            />
            <Space w="xs" />
          </Group>
        </SimpleGrid>
        <TextInput
          radius="md"
          size="md"
          rightSection={
            searchValue && (
              <ActionIcon onClick={() => setSearchValue('')}>
                {' '}
                <X />{' '}
              </ActionIcon>
            )
          }
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <ScrollArea
          h={'85vh'}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table striped verticalSpacing="xs" fontSize="md">
            <thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <tr>
                <th>{t('ApprovalTable.RequirementNo')}</th>
                <th>{t('ApprovalTable.ReportingDate')}</th>
                <th>{t('ApprovalTable.Status')}</th>
                <th>{t('ApprovalTable.Priority')}</th>
                <th>{t('ApprovalTable.Quantity')}</th>
                <th>{t('ApprovalTable.Resource')}</th>
                <th>{t('ApprovalTable.DeliveryLocation')}</th>
                <th>{t('main.InquiryFrom')}</th>
                <th>{t('ApprovalTable.WaitIn')}</th>
                <th>{t('main.Supplier')}</th>
                <th>{t('main.requester')}</th>
                <th>{t('main.recipient')}</th>
                <th>{t('main.Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {createRows(
                ctx.entities,
                userInfo?.user_id || 0,
                userInfo?.section_id || 0,
                showAllEntries,
                searchValue,
              )}

              {ctx.loading &&
                /* TODO ajust skeleton */
                [1, 2].map((id) => {
                  return (
                    <tr key={id}>
                      <td>
                        <Skeleton height={12} mt={6} width="50%" radius="xl" />
                      </td>
                      <td>
                        <Skeleton height={12} mt={6} width="70%" radius="xl" />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </ScrollArea>
        {!ctx.loading && ctx.entities.length == 0 && (
          <Text align="center">{t('ApprovalTable.NoRequirement')}</Text>
        )}
      </Stack>
    </Paper>
  );
}

export default ApprovalTable;
